import { FC, ReactNode, Suspense } from 'react';

import { TheErrorBoundary } from '../../App/error-logging/error-boundary';
import { WithChildren } from '../WithChildren';

export const LazyComponentLoader: FC<WithChildren & { fallback?: ReactNode; }>
  = ({ children, fallback }) => {
    return (
      <TheErrorBoundary>
        <Suspense fallback={fallback} children={children} />
      </TheErrorBoundary>
    );
  };