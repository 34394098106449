import { Auth0Provider } from '@auth0/auth0-react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { FC, lazy, StrictMode, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { AppProps } from './App/App';
import { LazyComponentLoader } from './common/components/LazyComponentLoader';
import { lightTheme } from './common/layout/themes';
import './index.css';

const LazyLoadedApp = lazy(() => import('./App/App'));

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const theme = createTheme(lightTheme);

const App: FC<AppProps> = ({ redirectTo, token, sub }) => {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <LazyComponentLoader>
          <LazyLoadedApp redirectTo={redirectTo} token={token} sub={sub} />
        </LazyComponentLoader>
      </BrowserRouter>
    </ThemeProvider>
  );
};

const AppRoot: FC = () => {
  const [redirectTo, setRedirectTo] = useState<string | undefined>();
  const query = new URLSearchParams(window.location.search);
  const token = process.env.NODE_ENV !== 'production' && query.get('token');
  const sub = process.env.NODE_ENV !== 'production' && query.get('sub');

  const app = (
    <App
      redirectTo={redirectTo ? redirectTo : token ? `?token=${token}&sub=${sub}` : undefined}
      token={token ? token : undefined}
      sub={sub ? sub : undefined}
    />
  );

  const AuthenticatedApp =
    token ? app : (
      <Auth0Provider
        children={app}
        clientId="uRDBOL23guhSdAnV3TDdsNOch5pxC3uq"
        domain="auth.brolisherdline.com"
        authorizationParams={{
          audience: 'https://api.brolisherdline.com',
          redirect_uri: window.location.origin,
        }}
        onRedirectCallback={(appState) => {
          if (appState?.returnTo && appState.returnTo !== redirectTo) {
            setRedirectTo(appState.returnTo);
          }
        }}
        cacheLocation="localstorage"
      />
    );

  return <StrictMode children={AuthenticatedApp} />;
};

root.render(<AppRoot />);