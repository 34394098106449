import { Color, HEX, RGB, RGBA } from '../../types';

export const hexToRgb = (hex: HEX, alpha: number): Color => {
  const r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')' as RGBA;
  } else {
    return 'rgb(' + r + ', ' + g + ', ' + b + ')' as RGB;
  }
};