import { HEX } from '../../types';

export const COLOR: Record<string, HEX> = {
  b1: '#FFFFFF',
  b3: '#F4F7FB',
  brolisDarkBlue: '#004B78',
  brolisLightBlue: '#6789A5',
  brolisYellow: '#E1BE00',
  j1: '#5C5C5C',
  m4: '#124583',
  m5: '#476074',
  p1: '#949494',
  p2: '#4E4E4E',
  r1: '#E30000',
  r2: '#FF7A00',
  v1: '#BD00FF',
  z1: '#09BF3C',
} as const;

export const PRIMARY_CHART_LINE_COLOR: HEX = '#2063A6';
export const SECONDARY_CHART_LINE_COLOR: HEX = '#1794D2';
export const TERTIARY_CHART_LINE_COLOR: HEX = '#FF7A00';

export const CHART_RED_COLOR: HEX = '#DB4A4A';
export const CHART_GREEN_COLOR: HEX = '#59C047';
export const PRIMARY_COLOR = COLOR.brolisLightBlue;
export const HOVER_COLOR: HEX = '#BECEDA';
export const CHART_GRID_LINE_COLOR = '#EDEFF3';

// USE getDiseaseColor() instead direct access
export const MFD_COLOR: HEX = '#FF0B9D';
export const MFD_LOW_COLOR: HEX = '#FF80CC';
export const ACUTE_COLOR: HEX = '#FFA800';
export const HEALTHY_COLOR = COLOR.z1;
export const NEB_COLOR: HEX = '#4200FF';
export const NEB_LOW_COLOR: HEX = '#6E7DFF';
export const OTHER_COLOR: HEX = '#6789A5';

export const HOVER_STYLE = {
  backgroundColor: HOVER_COLOR,
  color: '#FFF',
};

export const ALERT_WARNING_MESSAGE_TEXT_COLOR = '#5F2B01';
export const ALERT_WARNING_MESSAGE_BACKGROUND_COLOR = '#FDF0E6';

export const TABLE_ZEBRA_HIGHLIGHT_COLOR = '#F4F7FB';
