import { defaultTheme } from 'react-admin';

import { BACKGROUND_COLOR, HOVER_COLOR, PRIMARY_COLOR } from '../../utils/constants';
import { TOOLTIP_BACKGROUND_COLOR, TOOLTIP_COLOR, TOOLTIP_FILTER } from '../constants/tooltip';
import muiDateRangePickerStyles from './MuiDateRangePicker';
import { BACKDROP_COLOR, CLOSED_SIDEBAR_WIDTH, OPEN_SIDEBAR_WIDTH } from './theme.const';

// TODO: there are too many button style overrides bellow. We should find a
// nicer way to eliminate some of .selected :hover .inactive classes for button.

export const POPOVER_SHADOW = {
  boxShadow: '0px 13px 64px rgba(78, 78, 78, 0.19)',
};

export const lightTheme = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 800,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 1700,
      xxxl: 1800,
      xxxxl: 2200,
    },
  },
  sidebar: {
    closedWidth: CLOSED_SIDEBAR_WIDTH,
    width: OPEN_SIDEBAR_WIDTH,
  },
  typography: {
    fontFamily: 'Montserrat',
    fontWeight: '500',
  },
  palette: {
    primary: {
      main: PRIMARY_COLOR,
    },
    secondary: {
      light: '#5f5fc4',
      main: '#283593',
      dark: '#001064',
      contrastText: '#fff',
    },
    background: {
      default: BACKGROUND_COLOR,
    },
    mode: 'light' as const,
  },
  shape: {
    borderRadius: 6,
  },
  components: {
    ...defaultTheme.components,
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow: 'none',
        },
        root: {
          border: '',
          backgroundClip: 'padding-box',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorSecondary: {
          color: '#808080',
          backgroundColor: BACKGROUND_COLOR,
        },
      },
    },
    RaLoadingIndicator: {
      styleOverrides: {
        root: {
          display: 'none',
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: '#f5f5f5',
        },
        barColorPrimary: {
          backgroundColor: '#d7d7d7',
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          borderRadius: '5px',
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0, 0, 0, 0.04);',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          transition: 'initial',
          '& svg': {
            transition: 'initial',
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontWeight: 500,
        },
      },
    },
    ...muiDateRangePickerStyles,
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: BACKDROP_COLOR,
          '&.MuiBackdrop-invisible': {
            backgroundColor: 'transparent !important',
          },
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          '.MuiBackdrop-root': {
            backgroundColor: BACKDROP_COLOR,
          },
          '.MuiPaper-root': {
            ...POPOVER_SHADOW,
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: TOOLTIP_BACKGROUND_COLOR,
          color: TOOLTIP_COLOR,
          filter: TOOLTIP_FILTER,
        },
        arrow: {
          color: TOOLTIP_BACKGROUND_COLOR,
          filter: TOOLTIP_FILTER,
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          '.MuiButtonBase-root.Mui-selected': {
            backgroundColor: `${PRIMARY_COLOR} !important`,
          },

        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: '#FFF',
          },
          '&:hover': {
            backgroundColor: `${HOVER_COLOR} !important`,
            color: '#FFF',
          },
        },
      },
    },
    MuiPickersLayout: {
      styleOverrides: {
        root: {
          '.MuiPickersDay-root': {
            '&.Mui-selected': {
              backgroundColor: `${PRIMARY_COLOR} !important`,
            },
          },
        },
      },
    },
  },
};