import { dateRangePickerDayClasses } from '@mui/x-date-pickers-pro';

import { COLOR } from '../../utils/constants';
import { getCssTransition } from '../../utils/css/transition';
import { DIALOG_BORDER_RADIUS, SEPARATOR_LINE_STYLE } from './theme.const';

const dateRangePickerStyles = {
  MuiDateRangeCalendar: {
    styleOverrides: {
      root: {
        '.MuiTypography-subtitle1': {
          fontSize: '14px',
          fontWeight: 700,
          overflow: 'hidden',
          textTransform: 'capitalize',
          whiteSpace: 'nowrap',
        },
        '.MuiPickersArrowSwitcher-root': {
          padding: '16px 0 8px',
          justifyContent: 'flex-start',
          gap: '20px',
          svg: {
            fill: '#000',
          },
          '.Mui-disabled': {
            svg: {
              fill: 'rgba(0, 0, 0, 0.38)',
            },
          },
          '.MuiButtonBase-root': {
            borderRadius: DIALOG_BORDER_RADIUS,
            '&:hover': {
              svg: { fill: '#FFF' },
            },
          },
        },
        '.MuiPickersSlideTransition-root': {
          minWidth: '260px',
        },
        '.MuiDateRangeCalendar-monthContainer': {
          borderRight: 'none !important',
          // Day name separator
          '.MuiDayCalendar-header': {
            '&:after': {
              ...SEPARATOR_LINE_STYLE,
              height: '1px',
              top: '90px',
              width: 'calc(100% - 329px)',
            },
          },
          '&:nth-of-type(1)': {
            '[role="grid"]': {
              paddingRight: '30px',
            },
            '.MuiPickersArrowSwitcher-root': {
              paddingLeft: '5px',
            },
            // Day name separator
            '.MuiDayCalendar-header': {
              '&:after': {
                right: '324px',
              },
            },
            '&:after': {
              ...SEPARATOR_LINE_STYLE,
              height: 'calc(100% - 70px)',
              right: '290px',
              top: '30px',
              width: '1px',
            },
          },
          '&:nth-of-type(2)': {
            '[role="grid"]': {
              paddingLeft: '20px',
            },
            '.MuiPickersArrowSwitcher-root': {
              justifyContent: 'flex-end',
              paddingRight: '5px',
            },
            // Day name separator
            '.MuiDayCalendar-header': {
              '&:after': {
                right: '5px',
              },
            },
          },
        },
        //Day name line
        '.MuiDayCalendar-header': {
          '.MuiDayCalendar-weekDayLabel': {
            color: '#000',
            fontSize: '14px',
            fontWeight: '400',
            height: '28px',
            width: '32px',
          },
        },
        '.MuiDayCalendar-weekContainer': {
          margin: '6px 0',
        },
        '.MuiDayCalendar-monthContainer': {
          paddingTop: '15px',
          [`.${dateRangePickerDayClasses.day}`]: {
            borderRadius: 0,
            color: '#4E4E4E',
            fontSize: '14px',
            fontWeight: '400',
            height: '28px',
            transition: getCssTransition(['background-color', 'color'], '0.05s ease-in'),
            width: '32px',
            '&.Mui-selected': {
              backgroundColor: COLOR.m4,
              color: '#FFF',
            },
            [`&.${dateRangePickerDayClasses.dayInsideRangeInterval}:not(.MuiPickersDay-hiddenDaySpacingFiller)`]: {
              backgroundColor: '#EDEFF3',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.4)',
              },
            },
            '&.Mui-disabled': {
              color: 'rgba(0, 0, 0, 0.38)',
            },
            '&:hover, &.MuiPickersDay-today, &.Mui-selected': {
              borderRadius: DIALOG_BORDER_RADIUS,
            },
            '&.MuiPickersDay-today:not(.Mui-selected)': {
              border: '1px dashed #476074',
            },
            '&:hover': {
              color: '#FFF',
              border: 'none',
            },
          },
          '.MuiDateRangePickerDay-outsideCurrentMonth .MuiDateRangePickerDay-rangeIntervalPreview': {
            borderColor: '#FFF',
          },
          [`.MuiDateRangePickerDay-root:last-of-type, .${dateRangePickerDayClasses.rangeIntervalDayHighlightEnd}`]: {
            [`.${dateRangePickerDayClasses.day}, .${dateRangePickerDayClasses.rangeIntervalPreview}`]: {
              borderTopRightRadius: DIALOG_BORDER_RADIUS,
              borderBottomRightRadius: DIALOG_BORDER_RADIUS,
            },
          },
          [`.MuiDateRangePickerDay-root:first-of-type, .${dateRangePickerDayClasses.rangeIntervalDayHighlightStart}`]: {
            [`.${dateRangePickerDayClasses.day}, .${dateRangePickerDayClasses.rangeIntervalPreview}`]: {
              borderTopLeftRadius: DIALOG_BORDER_RADIUS,
              borderBottomLeftRadius: DIALOG_BORDER_RADIUS,
            },
          },
        },
        [`.${dateRangePickerDayClasses.rangeIntervalDayHighlight}`]: {
          [`.${dateRangePickerDayClasses.rangeIntervalPreview}`]: {
            borderColor: '#EDEFF3',
          },
        },
        '.MuiPickersDay-hiddenDaySpacingFiller': {
          borderRadius: '0px !important',
          border: 'none !important',
        },
      },
    },
  },
};

export default dateRangePickerStyles;