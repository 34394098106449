import { FC, lazy } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { WithChildren } from '../../common/WithChildren';
import { LazyComponentLoader } from '../../common/components/LazyComponentLoader';

const GenericError = lazy(() => import('../../common/components/messages/errors/GenericError'));

export const TheErrorBoundary: FC<WithChildren> = ({
  children,
}) => {
  const fallbackContent = () => (
    <LazyComponentLoader>
      <GenericError />
    </LazyComponentLoader>
  );
  return (
    <ErrorBoundary
      onError={(error, componentStack) => {
        console.error(
          'An error was caught while lazy loading a component using <LazyComponentLoader/>',
          JSON.stringify({ cause: { error, componentStack } }),
        );
      }}
      fallbackRender={fallbackContent}
      children={<>{children}</>}
    />
  );
};