import { hexToRgb } from '../../utils/color';
import { COLOR } from '../../utils/constants';

export const BACKDROP_COLOR = hexToRgb(COLOR.m4, 0.1);
export const CALENDAR_PICKER_WIDTH = '248px';
export const CLOSED_SIDEBAR_WIDTH = 80;
export const DIALOG_BORDER_RADIUS = '4px';
export const LAYOUT_PADDING = '20px';
export const MOBILE_LAYOUT_PADDING = LAYOUT_PADDING;
export const OPEN_SIDEBAR_WIDTH = 241;
export const WIDGET_SHADOW = '0px 4px 10px 0 rgba(78, 78, 78, 0.1)';

export const SEPARATOR_LINE_STYLE = {
  backgroundColor: 'rgba(0, 0, 0, 0.12)',
  content: '\'\'',
  position: 'absolute',
};